<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {getPMApi} from '@/api/pm'

/**
 * User list component
 */
export default {
  page: {
    title: "Supplier List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      supplierList: [],
      title: "Supplier List",
      items: [
        {
          text: "Supplier",
          href: "/",
        },
        {
          text: "List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "SupplierID",
          sortable: true,
        },
        {
          key: "Name",
          sortable: true,
        },
        {
          key: "Address",
          sortable: true,
        },
        {
          key: "Phone",
          sortable: true,
        },
        {
          key: "Email",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
  
  },
  methods: {

    queryList(p) {
      getPMApi().list({action: "SUPPLIER", page : {page : p, page_size: this.perPage}}).then((res)=>{
        this.supplierList = []
        res.data.map((o) =>{
        
          this.supplierList.push({
            id: o.id,
            supplier_id : o.code,
            name : o.legal_name,
            apt : o.apt,
            address :o.address,
            city_name : o.city_name,
            phone : o.phone1,
            email : o.email1,      
          })
          this.totalRows = res.page.total;
         
        }) 

      })
    },

    pageChange(p) {
      this.queryList(p)
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    delete_supplier(l) {
      getPMApi().delete_supplier({supplier_id: l.id}).then((res)=>{
        if (res.errCode == 0) {
          this.supplierList = this.supplierList.filter((e) => e.id != l.id)
          this.$alertify.message("Remove Supplier Success");
        } else {
          this.$alertify.error("Remove Supplier Failed code:" + res.errCode);
        }
      })
    }

  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    Contractor List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >


                  <b-dropdown
                    variant="white"
                    right
                    toggle-class="btn-link text-dark shadow-none"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item>Action</b-dropdown-item>
                    <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else here</b-dropdown-item>
                  </b-dropdown>
                  <!-- end dropdown -->
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="supplierList"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  class="table-check"
                >

                  <template  #cell(SupplierID)="data">
                    {{ data.item.supplier_id}}
                  </template>
                  <template #cell(Name)="data">
                    {{ data.item.name }}
                  </template>
                  <template #cell(Address)="data"> 
                    {{ data.item.address }}
                  </template>
                  <template #cell(Phone)="data">
                    {{ data.item.phone }}
                  </template>
                  <template #cell(Email)="data">
                    {{ data.item.email }}
                  </template>
                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="delete_supplier(data.item)">Delete</b-dropdown-item>
                      <b-dropdown-item>Edit</b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
